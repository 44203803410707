body {
	background: var(--c-brand-blackish);
}

// lander customizations
#truthArts {
	--_bg-color: var(--c-brand-blackish);
	--_content-color: var(--c-brand-whitish);
	--_site-pad: 24px;
	--_grid-gap: var(--_site-pad);
	--_section-gutter: 64px;
	--_section-spacing: calc(var(--_section-gutter) * 2);
	--_pane-padding: 40px;
	color: var(--_content-color);
	padding: var(--_site-pad);
	position: relative;
	isolation: isolate;
	max-width: 100vw;
	overflow: clip;

	&::before {
		content: "";
		background: var(--_bg-color);
		position: fixed;
		inset: 0;
		z-index: -1;
	}

	@include bp(lap) {
		--_section-gutter: var(--_pane-padding);
	}

	@include bp(hands) {
		--_pane-padding: 24px;
	}
}

// global masthead
#head {
	@include flex($justify: space-between, $gap: 16px);
	margin-bottom: var(--_section-spacing);
}

#hero {
	border-radius: var(--radius-small) var(--radius-small) var(--radius-big) var(--radius-big);
	background: var(--c-brand-charcoal);
	padding: 40px var(--_section-gutter);
	position: relative;
	isolation: isolate;
	overflow: hidden;
	z-index: 2;
	filter: drop-shadow(0 12px 40px black);

	.art {
		position: absolute;
		inset: 0;
		z-index: -1;

		.slide {
			@include img-fit;
			position: absolute;
			inset: 0;
			z-index: 1;

			img {
				transition: 4000ms ease;
			}

			&:not(.visible) {
				img {
					scale: 1.125;
					filter: grayscale(1);
				}
			}
		}
	}
}

#bigNav {
	@include flex($gap: 16px);
	font-variation-settings: "wght" var(--f-weight-heavy), "wdth" var(--f-width-normal);
	border-radius: var(--radius-small);
	background: var(--_bg-color);
	padding: 8px 8px 8px 16px;

	.active {
		text-decoration: underline !important;
		text-underline-offset: 4px;
		pointer-events: none;
	}

	.navItem {
		color: var(--c-brand-whitish);
		text-decoration: none;

		&:hover {
			color: var(--mix-charcoal);
		}
	}

	@include bp(hands) {
		padding: 0;
		background: none;

		a:not([class^="button"]) {
			display: none;
		}
	}
}

#intro {
	@include flex($direction: column, $align: flex-start, $gap: 40px);
	position: relative;
	isolation: isolate;
	padding-block: var(--_section-spacing);
	margin-top: var(--_site-pad);

	.copy {
		p {
			max-width: 56ch;
		}

		[class^="button"] {
			margin-top: 40px;
		}
	}

	&+section {
		margin-top: var(--_site-pad);
	}
}

#teamPreview {
	@include flex($align: stretch, $gap: calc(var(--_site-pad) / 2));
	position: absolute;
	z-index: -1;
	inset: 0;
	pointer-events: none;
	width: 100vw;
	overflow: hidden;

	.teammate {
		@include img-fit;
		border-radius: var(--radius-small);
		position: relative;
		overflow: hidden;
		flex: 1 0 20vw;
		opacity: 0.125;
		filter: grayscale(0.75);

		@include bp(hand) {
			flex-basis: 50vw;
		}
	}

	&::after {
		content: "";
		position: fixed;
		inset: 0;
		width: 100vw;
		left: calc(var(--_site-pad) * -1);
		background-image: linear-gradient(to right, var(--c-brand-blackish) 15%, transparent);
	}
}

.grid {
	--_pane-bg: var(--c-brand-blackish);
	--_column-count: 1;
	display: grid;
	grid-template-columns: repeat(var(--_column-count), 1fr);
	gap: var(--_grid-gap);

	&.grid-2 {
		--_column-count: 2;

		@include bp(lap) {
			--_column-count: 1;
		}
	}

	&.grid-3 {
		display: flex;
		flex-wrap: wrap;

		.gridPane {
			flex: 1;
			min-width: min(400px, 100%);
		}

	}

	&.grid-4 {
		--_column-count: 4;

		@include bp(desk) {
			--_column-count: 3;
		}

		@include bp(lap) {
			--_column-count: 2;
		}

		@include bp(hand) {
			--_column-count: 1;
		}
	}

	&.grid-5 {
		--_column-count: 5;
	}

	&+.grid,
	&+.solo,
	&+.gridStack {
		margin-top: var(--_grid-gap);
	}
}

.solo {
	.gridPane {
		aspect-ratio: 3 / 1;

		@include bp(desk) {
			aspect-ratio: auto;
		}
	}

	&+* {
		margin-top: var(--_site-pad);
	}
}

.gridStack {
	@include flex(column, stretch, flex-start, var(--_grid-gap));

	.grid+.grid {
		margin-top: 0;
	}

	&+.grid {
		margin-top: var(--_site-pad);
	}
}

.gridPane {
	@include flex(column, flex-start, flex-start, var(--_grid-gap));
	border-radius: var(--radius-small);
	padding: var(--_pane-padding);
	isolation: isolate;
	overflow: hidden;
	position: relative;
	background: var(--_pane-bg);


	.copy {
		max-width: 80ch;
	}

	&.bigArt {
		padding: 0;
		gap: 0;

		.art {
			width: 100%;
		}

		.words {
			@include flex(column, flex-start, flex-start, var(--_grid-gap));
			padding: var(--_pane-padding);
		}
	}

	[class^="button"] {
		margin-top: auto;
		background: var(--c-brand-whitish);
		color: var(--c-brand-blackish);
		border: 2px solid var(--c-brand-blackish);
	}

	p {
		text-wrap: balance;
		font-variation-settings: "wght" 300, "wdth" var(--f-width-normal);

		@include bp(lap) {
			text-wrap: wrap;
		}
	}

	&:not(.bigPane) {


		// p {
		// 	font-size: 1.25rem;
		// }

	}

	&.bigPane {
		@include bp(lap) {
			p {
				max-width: 34ch;
				font-size: 1.25rem;
			}
		}
	}

	.copy {
		pointer-events: none;

		&.inverse {
			color: var(--c-brand-blackish);
		}
	}

	.label.secondary {
		color: color-mix(in lab, var(--_pane-bg), var(--c-brand-whitish) 25%);
		background: color-mix(in lab, var(--_pane-bg), var(--c-brand-blackish));
	}

	&.centered {
		align-items: center;
		text-align: center;
	}
}

// #firstGrid {
// 	@include bp(desk) {
// 		--_column-count: 1;
// 	}
// }

#bigMister {
	@include img-fit(top);
	padding: 0;
	isolation: isolate;
	margin-bottom: calc(var(--_section-spacing) * -3);
	overflow: hidden;
	border-top-right-radius: var(--radius-big);
	border-top-left-radius: var(--radius-big);

	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		bottom: 0;
		z-index: 1;
		pointer-events: none;
		background-image: linear-gradient(to top, var(--c-brand-blackish), transparent);
	}

	@include bp(lap) {
		width: 100vw;
		transform: translateX(calc(var(--_site-pad) * -1));
		border-radius: 0;
		aspect-ratio: 1 /1;
		margin-bottom: calc(var(--_section-spacing) * -5);

		&::before {
			height: 150%;
		}

		&::after {
			display: none;
		}
	}
}

.gridHeader {
	pointer-events: none;
	filter: drop-shadow(40px -40px 80px black);
	margin-top: 0;
	padding-top: var(--_section-spacing);

	&+* {
		margin-top: var(--_grid-gap);
	}

	p {
		max-width: 80ch;
		margin-top: 0.5em;
	}
}

#blogGrid {
	@include flex($align: stretch, $gap: var(--_grid-gap), $wrap: wrap);
}

.articlePane {
	--_pane-bg: var(--c-brand-charcoal);
	min-width: min(240px, 100%);
	flex: 1 1 0%;

	&>* {
		pointer-events: none;
	}

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		z-index: -1;
		background: transparent url("../../public/i/clouds.png") no-repeat top center / cover;
		transition: 500ms ease;
		opacity: 0.125;
		transform-origin: bottom;
	}

	&:hover {
		background: color-mix(in lab, var(--c-brand-blackish), var(--c-brand-charcoal));
	}

	&:not(:hover) {
		&::before {
			opacity: 0.025;
			scale: 2;
			transform: translateY(25%);
		}
	}

	@include bp(hand) {
		padding: 24px;
	}
}

#burnPane {
	--_pane-bg: var(--c-brand-charcoal);

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		width: 200%;
		z-index: -1;
		background: transparent url("../../public/i/flame-big-grey.gif") no-repeat top 35% center / 100%;
		filter: contrast(2);
		mix-blend-mode: soft-light;
	}
}

#gobStack {
	// @include bp(desk) {
	// 	.grid {
	// 		--_column-count: 1;
	// 	}
	// }

	@include bp(lap) {
		.grid {
			--_column-count: 2;
		}
	}

	@include bp(hand) {
		.grid {
			--_column-count: 1;
		}
	}
}

#gobPane {
	--_pane-bg: var(--c-gob-yellow-400);
}

#gobbie {
	margin-left: auto;
	z-index: -1;
	transform-origin: bottom left;
	transform: translate(25%, var(--_pane-padding));
	margin-top: calc(var(--_pane-padding) * -3);

}

#storePane {
	--_pane-bg: var(--c-gob-whitish);

	.label {
		rotate: -1.5deg;
	}
}

#horde {
	@include img-fit;
	width: calc(100% + var(--_pane-padding) * 2);
	position: absolute;
	inset: 50% 0 0;
	z-index: -1;

	img {
		object-position: top;
	}

	// @include bp(desk) {
	// 	position: relative;
	// 	transform: translate(calc(var(--_pane-padding) * -1), 10%);
	// 	width: calc(100% + var(--_pane-padding) * 2);
	// }
}

#spitPane {
	--_pane-bg: var(--c-spit-green-600);

	&::before {
		content: "";
		position: absolute;
		inset: 0;
		z-index: -1;
		width: 110%;
		background: transparent url("../../public/i/spit-spots-set.png") no-repeat center / cover;
	}

	// @include bp(desk) {
	// 	&::before {
	// 		width: 165%;
	// 	}
	// }

	// @include bp(lap) {
	// 	&::before {
	// 		width: 100%;
	// 	}
	// }

	// @include bp(hands) {
	// 	&::before {
	// 		width: 105%;
	// 	}
	// }

	// @include bp(hand) {
	// 	&::before {
	// 		width: 155%;
	// 	}
	// }
}

#novelPane,
#levPane {
	.copy {
		max-width: 480px;
	}

	.art {
		position: absolute;
		z-index: -2;
	}

	&::before {
		content: "";
		position: absolute;
		z-index: -1;
	}
}

#novelPane {
	--_pane-bg: var(--c-brand-whitish);

	.art {
		inset: 0 0 0 auto;
		width: 130%;
		@include img-fit($position: bottom);
		mix-blend-mode: multiply;
	}

	&::before {
		inset: 0;
		background-image: linear-gradient(to right, var(--_pane-bg) 32%, transparent 65%);
	}

}

// #levSecretGrid {
// 	@include bp(hands) {
// 		--_column-count: 1;
// 	}
// }

#levPane {
	--_pane-bg: var(--c-brand-charcoal);

	.art {
		inset: 0 0 0 auto;
		width: 130%;
		@include img-fit($position: bottom);
		mix-blend-mode: overlay;
	}

	&::before {
		inset: 0;
		z-index: -1;
		background-image: linear-gradient(to right, var(--_pane-bg) 32%, transparent 65%);
	}
}

#risePane {
	--_pane-bg: var(--c-brand-red);

	.art {
		transform-origin: bottom right;
		transform: translateX(var(--_pane-padding));
		scale: 1.25;
	}
}

#deliveryPane {
	--_pane-bg: var(--c-gob-blue);

	.art {
		position: absolute;
		z-index: -1;
		bottom: 0;
		mix-blend-mode: multiply;
		transform: translateX(calc(var(--_pane-padding) * -1));
		width: calc(100% + var(--_pane-padding) * 2);

		@include bp(hands) {
			opacity: .25;
		}
	}
}

#crashPane {
	--_pane-bg: var(--c-gob-orange);

	.art {
		// aspect-ratio: 1.5 / 1;
		height: 100%;
		@include img-fit;
		overflow: hidden;
	}
}

#escapePane {
	--_pane-bg: var(--c-cake-peach);

	.art {
		height: 100%;
		// aspect-ratio: 2 / 1.25;
		@include img-fit(left);
		border-radius: var(--radius-small) var(--radius-small) 0 0;
		overflow: hidden;
	}

	.copy {
		max-width: 100%;
	}

	@include bp(hands) {
		.art {
			aspect-ratio: auto;
			min-height: 240px;
		}
	}
}

#secretPane {
	--_pane-bg: var(--c-meme-purp);

	.copy {
		max-width: 480px;
	}

	.art {
		width: 400px;
		right: 0;
		top: 0;
		position: absolute;
		z-index: -1;
		transform: translate(15%, -25%);

	}
}


#deliveryPane,
#escapePane,
#novelPane,
#levPane {
	.label {
		rotate: -0.75deg;
	}
}

#crashPane {
	.label {
		rotate: 0deg;
	}
}

#levPane {
	--_pane-bg: var(--c-brand-charcoal);
}

#teamGrid {
	.teammate {
		@include flex;
		border-radius: var(--radius-big);
		overflow: hidden;
	}

	.teammateName {
		text-align: center;
		padding: var(--_site-pad);
		position: absolute;
		bottom: 0;
		color: black;
		text-shadow: 2px 2px white, -4px -4px white;
	}
}

#digitalArt,
#digitalArtGrid {
	display: none !important;
}

#projectImage {}

#projectName {}

#projectLinks {}

#projectSocial {
	.socialLink {}
}

#digitalArtGrid {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: var(--_grid-gap);

	.project {
		outline: 1px solid red;
		display: block;
		color: white;

		&>*:not([class^="button"], .socialLink) {
			pointer-events: none;
		}
	}
}

.scrollGrid {}

#action {
	@include flex(column, center, flex-end, 40px);
	background: var(--c-brand-red);
	padding: 64px 16vw;
	border-radius: var(--radius-small);
	text-align: center;
	position: relative;
	isolation: isolate;
	overflow: hidden;

	@include bp(hands) {
		padding-inline: 24px;
	}

	@include bp(hands) {
		padding-block: 40px;
	}
}

#portalsPane {
	--_pane-bg: #0d1732;
	padding-bottom: 0;

	.art, .art img {
		width: 100%
	}
	[class^="button"] {
		margin-top: 0;
		margin-bottom: .5em;
	}

}

#gameGrid, #gobNovel {
	margin-top: var(--_site-pad);
}