// main logo
#truthLogo {
	--_color: var(--c-brand-whitish);
	@include flex($gap: 0.5em);
	font-size: 1.75rem;
	color: #fff;
	flex-shrink: 0;

	& > * {
		pointer-events: none;
	}

	&:hover {
		.logoMark {
			--_color: var(--c-brand-red);
			animation-play-state: running;
		}
	}
}

.logoMark {
	width: 2em;
	transition: font-size 500ms var(--ease-linear);
	animation: awake paused 1000ms forwards infinite var(--ease-linear);

	path {
		fill: var(--_color);
		transition: fill 500ms var(--ease-linear);
	}
}

.logoType {
	font-variation-settings: "wght" var(--f-weight-heavy), "wdth" var(--f-width-wide);
	font-size: 1em;
	line-height: 1;
	margin-top: 0.25em;

    @include bp(hand) {
        display: none;
    }
}

// custom cursor
body,
a {
	// cursor: none;
}

.target {
	transition: scale 500ms var(--ease-linear);

	&:hover {
		scale: 1.025;
	}
}

// .customCursor {
// 	--_size: 20px;
// 	position: fixed;
// 	width: var(--_size);
// 	aspect-ratio: 1 / 1;
// 	border: 2px solid #fff;
// 	border-radius: 50%;
// 	transform: translate(-50%, -50%);
// 	pointer-events: none;
// 	z-index: 9999;
// 	transition: scale 500ms var(--ease-linear);

// 	&.targeting {
// 		scale: 1.25;

// 		&--brand {
// 			border-color: var(--c-brand-red);
// 		}

// 		&--neutral {
// 			border-color: inherit;
// 		}

// 		&--gob {
// 			border-color: var(--c-gob-blue);
// 		}
// 	}
// }

@media (hover: none) {
	body {
		cursor: default;
	}

	.customCursor {
		display: none;
	}
}

// art
.art {
	pointer-events: none;

	&.layers {
		display: grid;
		grid-template-areas: "layer";

		.artLayer {
			grid-area: layer;
		}
	}
}

// edge
.edge {
	position: relative;

	&::after {
		content: "";
		position: absolute;
		inset: 0;
		border-radius: inherit;
		pointer-events: none;
		border: 2px solid rgba(255, 255, 255, 0.04);
		mix-blend-mode: color-dodge;
	}

	&:hover {
		&::after {
			border: 2px solid rgba(255, 255, 255, 0.1);
		}
	}
}

section {
	margin-top: var(--_section-spacing);
	padding-inline: var(--_section-gutter);
	position: relative;

    @include bp(hands) {
        padding-inline: 0;
    }
}

.token {
	@include flex;
	@include img-fit;
	border-radius: var(--radius-small);
	overflow: hidden;
}

.burning {
	& > img {
		filter: grayscale(1);
	}

	&::before {
		content: "";
		background: transparent url("../../public/i/flame.gif") no-repeat top center / 100%;
		position: absolute;
		inset: 0;
		z-index: 1;
		scale: 1.125;
	}
}

.wigglySides {
	padding-block: 12px;
	position: relative;

	&::before,
	&::after {
		content: "";
		background: transparent url("../../public/i/wiggle-line.png") no-repeat center / 100%;
		inset: 0;
		height: 2px;
		position: absolute;
	}

	&::after {
		top: auto;
		scale: -1 -1;
	}
}



.labelGroup {
	@include flex($justify: flex-start, $gap: 2px);
}

.label {
	border-radius: var(--radius-small);
	background: var(--c-brand-blackish);
	max-width: fit-content;
	padding: 6px 8px;
	rotate: 1deg;
	text-align: center;

	&.secondary {
		color: rgba(255, 255, 255, 0.5);
		color: var(--mix-charcoal);
		rotate: -2deg;
	}
}

[class^="button"] {
	@include flex($type: inline-flex);
	@extend h5;
	border-radius: var(--radius-small);
	background: var(--c-brand-red);
	text-decoration: none;
	line-height: 1;
	color: var(--c-brand-whitish);
	color: color-mix(in lab, var(--c-brand-red), currentColor 95%);
	min-height: 2.75rem;
	padding-inline: 12px;
	position: relative;
	isolation: isolate;
    rotate: -0.5deg;
	text-align: center;

	&::after {
		content: "";
		position: absolute;
		inset: 2px;
		background: linear-gradient(to top, color-mix(in lab, currentColor, var(--c-brand-red)), transparent);
		border-radius: calc(var(--radius-small) - 2px);
		z-index: -1;
        opacity: 0.125;
	}
	&.disabled {
		pointer-events: none;
		opacity: 0.75;
	}
	&.inverse {
		background: var(--c-brand-charcoal);
	}
	&:hover {
		color: white;
		background: color-mix(in lab, currentColor, var(--c-brand-blackish) 95%);
        rotate: 0deg;
	}
}

.button {
	&--big {
		font-size: 1.75rem;
		min-height: 4rem;
		padding-inline: 24px;
	}
}

.toggle {
    position: absolute;
    width: 32px;
    aspect-ratio: 1 / 1;
    right: 12px;
    top: 12px;
    z-index: 2;
    border-radius: calc(var(--radius-small) - 4px);
    background: var(--c-brand-whitish);
    rotate: -1deg;

    &::after {
        content: "";
        position: absolute;
        inset: 0;
		background: transparent
		url("data:image/svg+xml,%3Csvg width='36' height='37' viewBox='0 0 36 37' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.8231 19.9651C18.4115 17.3766 20.8782 14.4129 23.8875 12.3376C25.3831 11.3061 26.7178 9.99547 28.0062 8.70715C28.3047 8.40863 29.4443 7.64868 29.3435 7.13854C29.1526 6.17231 29.9123 8.12784 29.8999 8.66365C29.8628 10.2803 30.2946 12.0291 30.3531 13.6586C30.3875 14.6169 30.6251 15.8706 30.4653 16.7844C30.3612 17.3798 30.7186 14.5536 30.6788 13.8982C30.5571 11.8942 31.5601 7.41788 29.7339 5.59169C29.3599 5.21765 27.8088 5.50521 27.4432 5.51811C25.7732 5.57707 24.0613 5.53929 22.4039 5.62334C21.2108 5.68384 20.5493 5.78909 19.3774 5.30903' stroke='black' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M28.356 23.4631C28.356 24.951 28.356 26.4389 28.356 27.9268C28.356 28.7966 28.5789 30.0237 28.3223 30.8577C28.0422 31.768 27.5852 32.591 26.6716 32.9463C25.2585 33.4959 23.6345 33.6201 22.1321 33.6201C21.1958 33.6201 20.2542 33.7596 19.3276 33.8896C18.0421 34.07 16.6522 33.9233 15.3523 33.9233C13.3272 33.9233 11.3243 34.2265 9.28844 34.2265C7.03499 34.2265 4.43163 33.7237 3.79724 31.1861C3.02139 28.0827 2.43286 25.0073 2.43286 21.7871C2.43286 17.6517 2.62484 13.5469 2.73606 9.43188C2.75513 8.72599 3.11219 7.21868 3.88146 6.93895C4.8589 6.58351 5.89508 6.63575 6.92183 6.63575C8.06424 6.63575 9.20569 7.09055 10.3833 7.09055C11.2508 7.09055 12.0354 7.07464 12.7415 7.54534' stroke='black' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E%0A")
		no-repeat center / 20px;
    }

	@include bp(hand) {
		width: 24px;
		&::after {
			background-size: 16px;
		}
	}
}

.hasToggle {
    &:not(:hover) {
        .toggle {
            opacity: 0;
        }
    }
}

// faq
.faqItem {
	
    .toggle::after {
        background: transparent
        url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.75 2.24992C11.8416 2.36443 11.8341 2.60516 11.8365 2.74013C11.8444 3.19833 11.8463 3.65323 11.8301 4.11144C11.7982 5.00839 11.759 5.92659 11.7932 6.82362C11.8417 8.09436 11.7506 9.35893 11.7916 10.6316C11.8313 11.8608 11.7865 13.1104 11.6923 14.3354C11.6036 15.4881 11.6252 16.6747 11.6859 17.8277C11.7277 18.6221 11.8653 19.4103 11.8653 20.2083C11.8653 20.7194 11.8475 21.2555 11.75 21.743' stroke='black' stroke-width='4' stroke-linecap='round'/%3E%3Cpath d='M2.07471 12.0747C2.34566 11.9906 2.63911 11.987 2.91943 11.966C4.06397 11.8804 5.21249 11.8514 6.35968 11.8241C8.50978 11.7729 7.33216 11.7905 9.48302 11.8037C10.9874 11.8129 10.4523 11.7531 11.9494 11.555C12.2333 11.5174 17.8995 11.5083 18.186 11.555C18.8559 11.664 19.5925 11.6842 20.2644 11.775C20.6558 11.8279 21.0605 11.8096 21.4543 11.8469C21.5721 11.858 21.7439 11.8906 21.8539 11.8539' stroke='black' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E%0A")
        no-repeat center / 20px;
    }

    &:hover:not(:has(.answer)) {
		background: color-mix(in lab, currentColor, var(--c-brand-blackish) 95%);
	}

	&:hover,
	&:has(.answer) {
		.question {
			color: white;
		}
	}

	&:has(.answer) {
		.question {
			scale: none;
		}
		.toggle {
			opacity: 1;
			&::after {
				background-image: url("data:image/svg+xml,%3Csvg width='24' height='20' viewBox='0 0 24 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.07471 10.0747C2.34566 9.99062 2.63911 9.98703 2.91943 9.96605C4.06397 9.8804 5.21249 9.85141 6.35968 9.82409C8.50978 9.7729 7.33216 9.79051 9.48302 9.80371C10.9874 9.81294 10.4523 9.75313 11.9494 9.55499C12.2333 9.51741 17.8995 9.50834 18.186 9.55499C18.8559 9.66405 19.5925 9.68423 20.2644 9.77502C20.6558 9.82792 21.0605 9.80957 21.4543 9.84688C21.5721 9.85803 21.7439 9.89055 21.8539 9.85389' stroke='black' stroke-width='4' stroke-linecap='round'/%3E%3C/svg%3E%0A");
			}
		}
	}
}

.question {
	transform-origin: left;
    padding-right: 64px;

}

.answer {
	padding-top: 8px;
    &:has(a:hover) {
        color: var(--mix-charcoal);
    }
}
.wiggleDivide {
	height: 4px;
	width: 100%;
	opacity: 0.1;

	img {
		width: 100%;
		height: 100%;
		object-fit: 100%;
	}
}